import React, {Suspense, lazy} from "react";
import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import {ADMIN_PATH} from "src/data/adminPath";
import routes from "src/routes";
import SuspenseLoading from "src/routes/SuspenseLoading";

const NotFoundPage = lazy(() => import("src/pages/NotFoundPage"));
const AdminRouter = lazy(() => import("src/admin/routes/AdminRouter"));

const AppRouter = () => {
    return (
        <Router>
            <Suspense fallback={<SuspenseLoading />}>
                <Switch>
                    {Object.entries(routes).map(
                        ([key, route]) =>
                            route.page && (
                                <Route exact path={route.url(":id")} key={key}>
                                    {route.page}
                                </Route>
                            ),
                    )}
                    <Route path={ADMIN_PATH}>
                        <AdminRouter />
                    </Route>
                    <Route>
                        <NotFoundPage />
                    </Route>
                </Switch>
            </Suspense>
        </Router>
    );
};

export default AppRouter;
