import {useEffect, useState} from "react";
import {darkTheme, lightTheme} from "src/styles/Theme.styles";

const useCustomTheme = () => {
    const [theme, setTheme] = useState(lightTheme);

    useEffect(() => {
        const prefersDark =
            window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches;

        if (prefersDark) {
            setTheme(darkTheme);
        }
    }, []);

    return {theme};
};

export default useCustomTheme;
