import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {Languages} from "src/interfaces/ILanguage";
import enTranslations from "./locales/en/translations.json";

export const resources = {
    en: enTranslations,
};

const fallbackLng = Languages.en;

i18n.use(initReactI18next).init({
    resources,
    fallbackLng,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
