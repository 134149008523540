import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://4f2281b2aa864b9b81164a4491b53581@sentry.i.kefir.games/6",
    });
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root"),
);
