import React from "react";
import {ThemeProvider} from "styled-components";
import useCustomTheme from "src/hooks/useCustomTheme";
import "src/i18n/i18n.config";
import AppRouter from "src/routes/AppRouter";

const App = () => {
    const {theme} = useCustomTheme();

    return (
        <ThemeProvider theme={theme}>
            <AppRouter />
        </ThemeProvider>
    );
};

export default App;
