import React, {lazy} from "react";
import {IRoutes} from "src/routes/IRoutes";

const StorePage = lazy(() => import("src/pages/StorePage"));

const DemoGooglePlay = lazy(() => import("src/pages/DemoGooglePlay"));
const DemoAppStore = lazy(() => import("src/pages/DemoAppStore"));

const PreviewAppStorePage = lazy(() => import("src/pages/PreviewAppStorePage"));
const PreviewGooglePlayPage = lazy(
    () => import("src/pages/PreviewGooglePlayPage"),
);

const routes: IRoutes = {
    store: {
        title: "",
        url: (param) => `/store/${param}`,
        page: <StorePage />,
    },
    demoGooglePlay: {
        title: "Демо GooglePlay",
        url: () => "/g",
        page: <DemoGooglePlay />,
    },
    demoAppStore: {
        title: "Демо AppStore",
        url: () => "/a",
        page: <DemoAppStore />,
    },
    previewAppStore: {
        title: "Предпросмотр AppStore",
        url: (param) => `/preview/app-store/${param}`,
        page: <PreviewAppStorePage />,
    },
    previewGooglePlay: {
        title: "Предпросмотр GooglePlay",
        url: (param) => `/preview/google-play/${param}`,
        page: <PreviewGooglePlayPage />,
    },
};

export default routes;
