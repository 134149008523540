import {DefaultTheme} from "styled-components";
import {IFontsAppStore, IFontsGooglePlay} from "src/interfaces/IFonts";

declare module "styled-components" {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    export interface DefaultTheme {
        colors: {
            bg: string;
        };

        offset: {
            xs: string;
            sm: string;
            md: string;
        };

        admin: {
            colors: {
                navbar: string;
                sideBarColor: string;
                sideBarBg: string;
                sideBarBgHover: string;
                sideBarBgActive: string;
                sideBarBgSelected: string;
                stars: string;
            };
        };

        googlePlay: {
            colors: {
                primaryText: string;
                secondaryText: string;
                tertiaryText: string;
                invertedText: string;
                subtitle: string;
                tag: string;
                tagSelected: string;
                tagSelectedBg: string;
                link: string;
                secondLink: string;
                invertedLink: string;
                bg: string;
                icon: string;
                border: string;
                bgButton: string;
                separator: string;
                bgPopover: string;
                bgFocusPopover: string;
                popoverText: string;
                shadow: string;
                sortIcon: string;
            };
            fonts: IFontsGooglePlay;
            offset: {
                md: string;
            };
        };

        appStore: {
            colors: {
                primaryText: string;
                secondaryText: string;
                tertiaryText: string;
                link: string;
                invertedLink: string;
                bg: string;
                bgTransparent: string;
                secondaryBg: string;
                border: string;
                gradient: string;
                secondGradient: string;
                stars: string;
            };
            fonts: IFontsAppStore;
            offset: {
                md: string;
            };
        };
    }
}

const GooglePlayFonts: IFontsGooglePlay = {
    roboto: '"Roboto", sans-serif',
    googleSans: '"Google Sans", sans-serif',
};

const AppStoreFonts: IFontsAppStore = {
    SFProText: '"SF Pro Text", Arial, sans-serif',
    SFCompactRounded: '"SF Compact Rounded", Arial, sans-serif',
};

export const lightTheme: DefaultTheme = {
    colors: {
        bg: "white",
    },

    offset: {
        xs: "1rem",
        sm: "2rem",
        md: "4.375rem",
    },

    admin: {
        colors: {
            navbar: "#f7f7fa",
            sideBarColor: "white",
            sideBarBg: "#22262e",
            sideBarBgHover: "#262a33",
            sideBarBgActive: "#202328",
            sideBarBgSelected: "#333d50",
            stars: "#004299",
        },
    },

    googlePlay: {
        colors: {
            primaryText: "rgba(0, 0, 0, 0.6)",
            secondaryText: "rgba(0, 0, 0, 0.8)",
            tertiaryText: "rgba(0, 0, 0, 0.4)",
            invertedText: "white",
            subtitle: "rgba(0, 0, 0, 0.8)",
            tag: "rgba(0, 0, 0, 0.6)",
            tagSelected: "#092C62",
            tagSelectedBg: "#C3E7FF",
            link: "#0B57CF",
            secondLink: "#0b4eba",
            invertedLink: "#DBDBDB",
            bg: "white",
            icon: "rgba(0, 0, 0, 0.8)",
            border: "rgba(0, 0, 0, 0.4)",
            bgButton: "rgba(0, 0, 0, 0.2)",
            separator: "rgba(0, 0, 0, 0.1)",
            bgPopover: "#F4F7F9",
            bgFocusPopover: "#E8EEF2",
            popoverText: "#666666",
            shadow: "rgba(0, 0, 0, 0.06)",
            sortIcon: "#333333",
        },
        fonts: GooglePlayFonts,
        offset: {
            md: "1.25rem",
        },
    },

    appStore: {
        colors: {
            primaryText: "#171717",
            secondaryText: "#8A8A8D",
            tertiaryText: "#8D8F93",
            link: "#3478F6",
            invertedLink: "white",
            bg: "white",
            bgTransparent: "rgba(23, 23, 23, 0.1)",
            secondaryBg: "#F2F1F7",
            border: "#C6C6C8",
            gradient:
                "linear-gradient(270deg, #FFF 57.03%, rgba(255, 255, 255, 0.00) 100%)",
            secondGradient:
                "linear-gradient(270deg, #F2F1F7 57.03%, rgba(242, 241, 247, 0.00) 100%)",
            stars: "#FF9900",
        },
        fonts: AppStoreFonts,
        offset: {
            md: "1.25rem",
        },
    },
};

export const darkTheme: DefaultTheme = {
    colors: {
        bg: "#131313",
    },

    offset: {
        xs: "1rem",
        sm: "2rem",
        md: "4.375rem",
    },

    admin: {
        colors: {
            navbar: "#f7f7fa",
            sideBarColor: "white",
            sideBarBg: "#22262e",
            sideBarBgHover: "#262a33",
            sideBarBgActive: "#202328",
            sideBarBgSelected: "#333d50",
            stars: "#004299",
        },
    },

    googlePlay: {
        colors: {
            primaryText: "rgba(255, 255, 255, 0.8)",
            secondaryText: "rgba(255, 255, 255, 0.9)",
            tertiaryText: "rgba(255, 255, 255, 0.6)",
            invertedText: "#092C62",
            subtitle: "rgba(255, 255, 255, 0.8)",
            tag: "rgba(255, 255, 255, 0.6)",
            tagSelected: "white",
            tagSelectedBg: "#004A77",
            link: "#A8C8FB",
            secondLink: "#8eacdc",
            invertedLink: "rgba(255, 255, 255, 0.20)",
            bg: "#131313",
            icon: "rgba(255, 255, 255, 0.9)",
            border: "rgba(255, 255, 255, 0.40)",
            bgButton: "rgba(0, 0, 0, 0.2)",
            separator: "rgba(255, 255, 255, 0.20);",
            bgPopover: "#232323",
            bgFocusPopover: "#333333",
            popoverText: "rgba(255, 255, 255, 0.8)",
            shadow: "rgba(0, 0, 0, 0.06)",
            sortIcon: "rgba(255, 255, 255, 0.9)",
        },
        fonts: GooglePlayFonts,
        offset: {
            md: "1.25rem",
        },
    },

    appStore: {
        colors: {
            primaryText: "white",
            secondaryText: "#8A8A8D",
            tertiaryText: "#8D8F93",
            link: "#0385FF",
            invertedLink: "white",
            bg: "black",
            secondaryBg: "#1C1C1E",
            bgTransparent: "rgba(255, 255, 255, 0.2)",
            border: "rgba(198, 198, 200, 0.30)",
            gradient:
                "linear-gradient(270deg, #000 57.03%, rgba(0, 0, 0, 0.00) 100%)",
            secondGradient:
                "linear-gradient(270deg, #1C1C1E 57.03%, rgba(28, 28, 30, 0.00) 100%)",
            stars: "#FF9900",
        },
        fonts: AppStoreFonts,
        offset: {
            md: "1.25rem",
        },
    },
};
